import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import JoinCommunitySection from '../components/JoinCommunitySection';
import NewHeroLanding from '../components/NewHeroLanding';
import RoadMap from '../components/RoadMap';
import SubscribeSection from '../components/SubscribeSection';
import Footer from '../components/v2/Footer';
import GameTabs from '../components/v2/GameTabs';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import NFTs20Section from '../components/v2/NFTs20Section';
import Partners from '../components/v2/Partners';

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Paima Studios')} mainWrapperClass="bg-engine-black">
      <Header redirectToBuyNft />
      <CookieConsentBanner />
      <NewHeroLanding />
      <Partners />
      <GameTabs />
      <NFTs20Section />
      {/* commented out because of dysfunctional cover images */}
      {/* <LatestNews />  */}
      <JoinCommunitySection />
      <RoadMap />
      <SubscribeSection />
      <Footer />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
