import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

interface Item {
  title: string | ReactElement;
  active: boolean;
}

const quarter4: Item[] = [
  {
    title: <Trans>Launch of Paima Studios Website</Trans>,
    active: true
  },
  {
    title: <Trans>Launch of Volcaneers NFTs</Trans>,
    active: true
  },
  {
    title: <Trans>Paima Engine - Private Alpha MVP</Trans>,
    active: true
  },
  {
    title: <Trans>Jungle Wars: NFT Rumble - OG Tournament</Trans>,
    active: true
  },
  {
    title: <Trans>Launch of Jungle Wars: NFT Rumble</Trans>,
    active: true
  }
];

const quarter1: Item[] = [
  {
    title: <Trans>Paima Whirlpool - Cross-chain Gameplay</Trans>,
    active: true
  },
  {
    title: <Trans>Tarochi: Monster Chronicles Announced</Trans>,
    active: true
  },
  {
    title: <Trans>Launch of Wrath Of The Jungle: Tower Defense</Trans>,
    active: true
  }
  // {
  //   title: <Trans>Research about NFT Bridge</Trans>,
  //   active: false
  // }
];

const quarter2: Item[] = [
  {
    title: <Trans>Stateful NFTs Marketplace</Trans>,
    active: false
  },
  {
    title: <Trans>Update About Upcoming Game #4</Trans>,
    active: false
  },
  {
    title: <Trans>Update About New Paima Engine Capabilities</Trans>,
    active: false
  }
];

const quarter3: Item[] = [
  {
    title: <Trans>More Info Soon</Trans>,
    active: false
  },
  {
    title: <Trans>More Info Soon</Trans>,
    active: false
  },
  {
    title: <Trans>More Info Soon</Trans>,
    active: false
  },
  {
    title: <Trans>More Info Soon</Trans>,
    active: false
  }
];

const SectionHeader = ({ title, active = false }: { title: string; active?: boolean }) => {
  return (
    <h3
      className={classNames(
        'p-12',
        'rounded-full',
        active && 'bg-primary-color',
        active ? 'text-white' : 'text-engine-gray border-2 border-primary-color',
        'text-20',
        'font-heading',
        'text-center'
      )}
    >
      {title}
    </h3>
  );
};

const SectionList = ({ items }: { items: Item[] }) => {
  return (
    <ul className="space-y-20">
      {items.map((item, index) => (
        <li className="flex items-start gap-8" key={index}>
          <img
            src={item.active ? '/images/icon-check.svg' : '/images/icon-no-check.svg'}
            width="20"
            height="20"
            alt=""
            className="relative top-[3px]"
          />
          <p className={classNames(item.active ? 'text-white' : 'text-engine-gray')}>{item.title}</p>
        </li>
      ))}
    </ul>
  );
};

const RoadMap = () => {
  return (
    <section
      id="road-map-section"
      className="relative bg-engine-green oval-clip oval-clip--engine-green text-white pb-40 lg:pb-80 border-b border-b-engine-border"
    >
      <div className="oval-clip-safeguard" />
      <div className="container relative flex justify-center items-center z-10">
        <div className="flex flex-col w-full items-center">
          <h2 className="text-40 md:text-48 font-heading font-bold mb-56 mt-80 text-center">
            <Trans>Road Map</Trans>
          </h2>
          <div className="flex w-full justify-between flex-wrap mb-24">
            <div className="w-full md:w-1/2 lg:w-1/4 items-center p-12 container-pill-second">
              <SectionHeader title="Stage: Egg Hatching" active />
              <p className="text-center font-base text-black py-16 font-semibold"></p>
              <SectionList items={quarter4} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 items-center p-12 container-pill">
              <SectionHeader title="Stage: The Basin" active />
              <p className="text-center font-base text-grey-200 py-16 font-semibold"></p>
              <SectionList items={quarter1} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 items-center p-12 container-pill">
              <SectionHeader title="Stage: Dinizia" />
              <p className="text-center font-base text-grey-200 py-16 font-semibold"></p>
              <SectionList items={quarter2} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 items-center p-12">
              <SectionHeader title="Stage: The Kaieteur" />
              <p className="text-center font-base text-grey-200 py-16 font-semibold"></p>
              <SectionList items={quarter3} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
