import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CheckIcon from '../../../static/images/icon-green-check.inline.svg';

const textList = [
  {
    title: <Trans>Stateful NFTs Grow With The Player</Trans>,
    text: (
      <Trans>
        With Stateful NFTs, the NFT can be a character, an in-game item, a piece of equipment, or in fact any piece of
        in-game state. This revolutionizes what is possible with NFTs, as now players can own NFTs that feel just like
        MMO characters which develop, find loot, and level up just as they've always wanted.
      </Trans>
    )
  },
  {
    title: <Trans>Players Truly Own Their Game Assets (No Hand-Waving)</Trans>,
    text: (
      <Trans>
        Unlike most 'Web3 Games' that don't use Paima Engine, all Stateful NFT data lives fully on-chain. This means
        that there is no centralized server that can be hacked, which would suddenly make the assets useless (which,
        unfortunately, is the standard with NFTs 1.0). With Stateful NFTs, we leave behind centralized intermediaries or
        bridges that can break and instead give players full control of their assets, backed by the soundness of the
        blockchain itself.
      </Trans>
    )
  },
  {
    title: <Trans>Flipping The NFT 1.0 Monetization Model</Trans>,
    text: (
      <Trans>
        With NFTs 1.0, the only valuable aspect of the NFT is its scarcity (due to the limits of the technology). As a
        result, gamers suffer because companies are incentivized to release very few in order to sell them at very high
        prices. Stateful NFTs flip this model, allowing companies to sell 'level 1' Stateful NFTs at low prices,
        enabling a large and excited community to grow in a way that naturally scales.
      </Trans>
    )
  }
];

const NFTs20Section = () => {
  return (
    <section className="oval-clip oval-clip--engine-green overflow-x-hidden relative z-20">
      <div className="oval-clip-safeguard" />
      <div className="bg-engine-green pt-40 md:pt-80 md:pb-80 lg:pt-80 lg:pb-120 pb-40 relative">
        <div className="container">
          <div className="flex flex-col lg:flex-row relative">
            <div className="md:max-w-[650px]">
              <h2 className="text-32 md:text-40 lg:text-48 text-white font-bold font-heading">
                <Trans>NFTs 2.0 - Stateful NFTs</Trans>
              </h2>
              <div className="mt-32">
                <span className="flex items-center gap-8 text-white mt-8 pb-20">
                  <Trans>
                    NFTs in gaming today (NFTs 1.0) have a bad reputation, and for good reason; they simply aren't
                    powerful enough on their own to build quality games that players want. Players want NFTs that feel
                    just as alive as their characters in an MMORPG, but instead have NFTs today that are as exciting as
                    a pet rock which never changes.
                  </Trans>
                </span>
                <span className="flex items-center gap-8 text-white mt-8 pb-20">
                  <Trans>
                    Stateful NFTs are the future, and Paima Engine is the first in the space to be pioneering this
                    brand-new technology, making it available for developers to easily build and integrate into their
                    own games.
                  </Trans>
                </span>
                <ul className="space-y-24">
                  {textList.map((item, index) => (
                    <li key={index}>
                      <span className="flex items-start gap-8 text-white font-bold text-20">
                        <span className="w-[20px] h-[20px] basis-[20px] relative top-[7px] lg:top-[8px]">
                          <CheckIcon />
                        </span>
                        <span className="block flex-1">{item.title}</span>
                      </span>
                      <p className="mt-8 text-white">{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="lg:flex lg:flex-col lg:justify-center">
              <img
                src="/images/paima-engine-nft-20.png"
                alt=""
                className="lg:-right-[35%] lg:relative lg:scale-125 2xl:scale-150 mt-40 lg:mt-0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NFTs20Section;
