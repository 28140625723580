import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useState } from 'react';

import LeftArrowIcon from '../../../static/images/icon-game-tab-arrow-left.inline.svg';
import { getLocalizedLink } from '../../utils/locale';

import VolcaneersLinkButton from './volcaneers/VolcaneersLinkButton';

interface Game {
  id: string;
  name: string | ReactElement;
  title: string | ReactElement;
  logoUrl?: string;
  description: string | ReactElement;
  url: string;
  backgroundClassName: string;
  buttonText: string | ReactElement;
  buttonDisabled?: boolean;
  details: {
    iconUrl: string;
    title: string | ReactElement;
    description: string | ReactElement;
    imageUrl: string;
  }[];
}

const gamesData: Game[] = [
  {
    id: 'tarochi',
    name: <Trans>Tarochi</Trans>,
    title: <Trans>Tarochi: Monster Chronicle</Trans>,
    logoUrl: '/images/tarochi.webp',
    backgroundClassName: "bg-[url('/images/paima-engine-game-tab-bg-td.webp')]",
    description: (
      <Trans>
        Tarochi is a ambitious onchain RPG, where every quest, every challenge, and every monster caught becomes a part of the blockchain history. Journey through a vast, immersive land, interacting with NPCs, unlocking achievements, and unraveling quests.
<br />
Dive into this revolutionary experience on the Xai network, made seamless by Arbitrum Orbit and the powerful Paima Engine. Best of all? Embark on this adventure without the hassle of bridging, and kickstart your legend for free!
      </Trans>
    ),
    url: 'https://tarochi.paimastudios.com/',
    buttonText: <Trans>Play Tarochi Now</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Full control over your assets</Trans>,
        description: (
          <Trans>
            Every monster you catch isn't just a game asset; it's a unique Stateful NFT, evolving with every in-game action. And not just monsters either - all game state is uniquely owned by you and tradeable anytime.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-tarochi-1.jpg'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>Free and smooth onchain experience</Trans>,
        description: (
          <Trans>
            Start playing Tarochi right away for free without having to connect any wallet
            Tarochi will create a wallet for you, and at any point you can transfer your game data to your main wallet. No bridging required.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-tarochi-2.png'
      }
    ]
  },
  {
    id: 'tower-defense',
    name: <Trans>WotJ: Tower Defense</Trans>,
    title: <Trans>Wrath Of The Jungle: Tower Defense</Trans>,
    logoUrl: '/images/tower-defense-logo.webp',
    backgroundClassName: "bg-[url('/images/paima-engine-game-tab-bg-td.webp')]",
    description: (
      <Trans>
        Experience the strategic thrill of Web3 PvP Tower Defense in Paima Studio's upcoming game. Will you fight with
        the animals of the jungle to defend their homes, or join the undead and summon crypts to overpower them? A
        classic game genre, now expanded into the world of Web3 gaming thanks to Paima Engine.
      </Trans>
    ),
    url: 'https://tower-defense.paimastudios.com/',
    buttonText: <Trans>Play Tower Defense Now</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>A PvP Twist To A Classic Genre</Trans>,
        description: (
          <Trans>
            Whether you're a gamer who enjoys the thrill of offense, or a classic TD veteran who excels in building an
            indomitable fortress of towers, Wrath Of The Jungle will quench your thirst for a competitive Tower Defense
            game.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-td-1.jpg'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>On Chain Gaming Meets Competitive Play</Trans>,
        description: (
          <Trans>
            WotJ is the first on-chain game to support real complexity in game logic, enabling a rich & exciting meta to
            be developed by the community. With Paima Whirlpool, it won't matter which crypto ecosystem you're in,
            you'll be able to play from your favorite wallet and prove your expertise.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-td-2.jpg'
      }
    ]
  },
  {
    id: 'jungle-wars',
    name: <Trans>Jungle Wars</Trans>,
    title: <Trans>Jungle Wars: NFT Rumble</Trans>,
    logoUrl: '/images/jungle-wars-logo.webp',
    backgroundClassName: "bg-[url('/images/paima-engine-game-tab-bg-jw.webp')]",
    description: (
      <Trans>
        The first casual PvP game was built to showcase the base tech underlying Paima Engine. Test your wits and
        craftiness while getting a feel for a completely new model of Web3 gaming.
      </Trans>
    ),
    url: '/junglewars/play',
    buttonText: <Trans>Play Jungle Wars: NFT Rumble Now</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Tactical Fully On-Chain Combat</Trans>,
        description: (
          <Trans>
            Outsmart your opponent in battle with Jungle Wars' deceptively simple yet psychological gameplay, and get a
            first taste of the future of on-chain Web3 gaming with Paima Engine. Learn what it takes to rise to the top
            of the Jungle, and acquire the sought-after #1 spot on the Volcaneer Stateful NFT leaderboard!
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-jw-1.jpg'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>Experience Cross-Chain Gameplay For The First Time</Trans>,
        description: (
          <Trans>
            Jungle Wars is the only on-chain Web3 game with full-fledged cross-chain support, thanks to our novel tech:
            Paima Whirlpool. Whether you're using Metamask, a Polkadot wallet, or a Cardano wallet (with more being
            added in the near future), you can start playing Jungle Wars immediately without having to install any new
            software!
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-jw-2.jpg'
      }
    ]
  },

  {
    id: 'auto-battler',
    name: <Trans>Stateful NFT Auto Battler</Trans>,
    title: <Trans>Stateful NFT Auto Battler</Trans>,
    backgroundClassName: "bg-[url('/images/paima-engine-game-tab-bg-jw.webp')]",
    description: (
      <Trans>
        What happens when the animals of the jungle have thousands of years to master technology? In this upcoming Paima
        Studio game, Stateful NFTs will play a pivotal role, giving players a taste of MMO-esque mechanics combined with
        exciting autobattler-based gameplay.
      </Trans>
    ),
    url: '',
    buttonText: <Trans>Coming Soon</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Own, Train, And Level Your Units</Trans>,
        description: (
          <Trans>
            Each unit on your team will be a Stateful NFT that initially starts at level 1. By playing PvE, your units
            will discover items, equipment, and gain experience, leading them to level up and learn new abilities! Enjoy
            the feeling of leveling up a character like in an MMO, while fully taking advantage of all the Web3 benefits
            of blockchains and Stateful NFTs.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-ab-1.jpg'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>A New Class Of PvE And PvP</Trans>,
        description: (
          <Trans>
            Reimagining the classic autobattler genre with units as Stateful NFTs that grow with you offers new
            opportunities for exciting gameplay. Train your units through adventures and boss battles, finding items and
            equipment along the way, which come in handy for crushing your friends and enemies in strategic PvP.
          </Trans>
        ),
        imageUrl: '/images/paima-engine-game-tab-ab-2.jpg'
      }
    ]
  }
];

const GameTabs = () => {
  const { i18n } = useTranslation();

  const [currentTab, setCurrentTab] = useState(gamesData[0].id);
  const currentGame = gamesData.find((game) => game.id === currentTab) as NonNullable<Game>;

  const handleMoveToNextProject = () => {
    const currentIndex = gamesData.findIndex((game) => game.id === currentTab);

    if (!!gamesData[currentIndex + 1]) {
      setCurrentTab(gamesData[currentIndex + 1].id);
    }
  };

  const handleMoveToPreviousProject = () => {
    const currentIndex = gamesData.findIndex((game) => game.id === currentTab);

    if (!!gamesData[currentIndex - 1]) {
      setCurrentTab(gamesData[currentIndex - 1].id);
    }
  };

  return (
    <section className="pb-[70vw] lg:pb-[600px] bg-engine-black relative">
      <div className="absolute left-0 right-0 top-[200px] md:top-[20vw] lg:top-[10vw] xl:-top-[5vw] 2xl:-top-[10vw] w-full">
        <img src="/images/paima-engine-game-tabs-line-bg-optimized.png" alt="" />
      </div>
      <div className="px-24 max-w-[1400px] mx-auto w-full relative z-20">
        <div className="flex items-center justify-between w-full lg:w-[100%] mb-16 lg:absolute lg:top-[80px] lg:left-0 lg:right-0 lg:mx-auto">
          <button
            aria-label="Previous project"
            className="w-[64px] h-[64px] flex items-center justify-center"
            onClick={() => handleMoveToPreviousProject()}
          >
            <div className="w-full h-full bg-engine-green lg:glass-bg hover:cursor-pointer rounded-full">
              <LeftArrowIcon />
            </div>
          </button>
          <button
            aria-label="Next project"
            className="w-[64px] h-[64px] flex items-center justify-center"
            onClick={() => handleMoveToNextProject()}
          >
            <div className="w-full h-full bg-engine-green lg:glass-bg hover:cursor-pointer rounded-full rotate-180">
              <LeftArrowIcon />
            </div>
          </button>
        </div>
        <div
          className={classNames(
            'rounded-[32px]',
            'bg-engine-green',
            'px-16',
            'py-24',
            'lg:px-120',
            'lg:py-64',
            'bg-cover',
            'bg-no-repeat',
            'bg-center',
            currentGame.backgroundClassName
          )}
        >
          <div className="mb-40">
            <ul className="hidden md:grid-cols-4 md:gap-16 md:grid">
              {gamesData.map((game) => (
                <li
                  key={game.id}
                  onClick={() => setCurrentTab(game.id)}
                  className={classNames(
                    'text-14',
                    'relative',
                    'text-center',
                    'hover:cursor-pointer',
                    game.id === currentTab ? 'text-white' : 'text-white/60'
                  )}
                >
                  {game.name}
                  <span
                    className={classNames(
                      'block',
                      'mt-16',
                      'w-full',
                      'h-[6px]',
                      'rounded-[32px]',
                      game.id === currentTab ? 'bg-white' : 'bg-white/20'
                    )}
                  />
                </li>
              ))}
              {/* <li
                className={classNames(
                  'text-14',
                  'relative',
                  'text-center',
                  'hover:cursor-not-allowed',
                  'text-white/60'
                )}
              >
                <Trans>And More Coming Soon...</Trans>
                <span className={classNames('block', 'mt-16', 'w-full', 'h-[6px]', 'rounded-[32px]', 'bg-white/20')} />
              </li> */}
            </ul>
            <div className="block md:hidden">
              <div className={classNames('text-14', 'relative', 'text-center', 'hover:cursor-pointer', 'text-white')}>
                {currentGame.name}
                <span className={classNames('block', 'mt-16', 'w-full', 'h-[6px]', 'rounded-[32px]', 'bg-white')} />
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row md:justify-between md:items-start">
            <div className="md:basis-[65%]">
              <h2 className="font-heading text-32 md:text-40 lg:text-48 text-white font-bold">{currentGame.title}</h2>
              <p className="text-16 text-engine-gray mt-16 max-w-[580px]">{currentGame.description}</p>
              <div className="mt-24 flex flex-col md:flex-row md:items-center gap-16">
                <VolcaneersLinkButton
                  sizeVariant="small"
                  uppercaseText={false}
                  overrideStyleClassnames={currentGame.buttonDisabled ? "pointer-events-none bg-transparent hover:bg-volcaneers-primary-dark text-volcaneers-primary-default border border-volcaneers-primary-default hover:text-white hover:border-volcaneers-primary-dark rounded-[115px] px-24 md:px-44 text-center disabled:bg-volcaneers-separator py-10 md:py-14 text-14 leading-5 font-normal" : undefined}
                  href={getLocalizedLink(currentGame.url, i18n.language)}
                >
                  {currentGame.buttonText}
                </VolcaneersLinkButton>
              </div>
            </div>
            <div className="md:basis-[20%] flex justify-start md:justify-end">
              {!!currentGame.logoUrl && (
                <div className="w-[400px] md:max-w-[400px] mb-24 md:mb-0 flex justify-start md:justify-end">
                  <img
                    src={currentGame.logoUrl}
                    alt={`Logo for ${currentGame.name}`}
                    className="max-w-[220px] max-h-[160px]"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-56">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-40">
              {currentGame.details.map((detail, index) => (
                <div key={index} className="flex flex-col">
                  <div className="flex-1">
                    <h3 className="flex items-start gap-8 text-20 text-white font-bold">
                      <img src={detail.iconUrl} alt="" className="w-[24px] h-[24px] relative top-[5px]" />
                      {detail.title}
                    </h3>
                    <p className="text-engine-gray mt-16">{detail.description}</p>
                  </div>
                  <img src={detail.imageUrl} alt="" className="mt-24 lg:mt-40" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        src="/images/paima-engine-game-tab-bg-image.webp"
        alt=""
        className="absolute left-0 right-0 bottom-0 w-full max-w-[800px] mx-auto z-10"
      />
    </section>
  );
};

export default GameTabs;
