import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

const partners = [
  {
    name: 'Microsoft',
    logo: 'logo-microsoft.png'
  },
  {
    name: 'Astar',
    logo: 'logo-astar.png'
  },
  {
    name: 'Milkomeda',
    logo: 'logo-milkomeda.png'
  },
  {
    name: 'Algorand',
    logo: 'logo-algorand.png'
  }
];

const Partners = () => {
  return (
    <section className="oval-clip">
      <div className="oval-clip-safeguard" />
      <div className="bg-engine-black pt-40 pb-10 md:pb-40">
        <div className="container">
          {/* <div style={{ minHeight: "128px" }} /> */}
          {/* <h2 className="text-white text-24 text-center font-bold">
            <Trans>Paima Ecosystem Partners</Trans>
          </h2>
          <ul className="grid grid-cols-2 md:grid-cols-4 gap-16 lg:gap-24 mt-40 mb-40 md:mb-80">
            {partners.map((partner, index) => (
              <li key={index} className="flex items-center justify-center">
                <img
                  src={`/images/${partner.logo}`}
                  alt={`Logo for ${partner.name}`}
                  className="max-w-[160px] lg:max-w-[220px]"
                />
              </li>
            ))}
          </ul> */}
        </div>
      </div>
    </section>
  );
};

export default Partners;
