import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import * as Analytics from '../services/analytics-utils';
import { getLocalizedLink } from '../utils/locale';

import VolcaneersButton from './v2/volcaneers/VolcaneersButton';

const NewHeroLanding = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    Analytics.pageview('Landing');
  }, []);

  return (
    <section className="bg-engine-green text-white pt-40 md:pt-80 pb-64 md:pb-120 lg:pb-[11vw] relative overflow-hidden">
      <img
        src="/images/paima-engine-hero-chip.webp"
        alt=""
        className="absolute lg:top-0 md:bottom-[6vw] right-0 2xl:right-[calc((100vw-1114px)/2-13vw)] lg:max-w-[798px] lg:max-h-[798px] md:max-w-[384px] md:max-h-[384px] z-0 hidden md:block"
      />

      <div className="container-slim z-10 relative">
        <div className="md:max-w-[640px]">
          <h1 className="text-32 sm:text-40 md:text-64 font-bold font-heading">
            <Trans>The World's Most Advanced Web3 Gaming Engine</Trans>
          </h1>
          <p className="text-18 text-engine-gray mt-16">
            <Trans>
              Paima Engine allows your team to reuse your Web2 programming skill sets to build the next generation of
              Web3 games and apps, powered by the latest innovations in Blockchain Layer-2 technology.
            </Trans>
          </p>
          <div className="mt-24 md:mt-32 flex flex-col gap-16 md:flex-row items-center justify-center md:justify-start">
            <a href={getLocalizedLink('/paima-engine', i18n.language)}>
              <VolcaneersButton sizeVariant="small" uppercaseText={false}>
                <Trans>Learn More About Paima Engine</Trans>
              </VolcaneersButton>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewHeroLanding;
