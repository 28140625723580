import { Trans } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { AnalyticsEventLocations,AnalyticsEvents } from '../analytic-events';
import { socialsConfig } from '../config';
import * as Analytics from '../services/analytics-utils';

const JoinCommunitySection = () => {
  return (
    <>
      <section
        id="join-community-section"
        className="relative bg-engine-black oval-clip bg-[url('/images/paima-engine-join-us-lines-optimized.png')] bg-no-repeat bg-cover"
      >
        <div className="oval-clip-safeguard" />
        <div className="container relative flex justify-center items-center z-10 ">
          <img
            className="w-full absolute top-[6vw] hidden lg:block z-0"
            src="/images/paima-engine-join-bg-characters.png"
            width="1080"
            height="652"
          />
          <div className="flex flex-col w-full items-center pt-[5vw] pb-[12vw] text-white">
            <h1 className="text-40 md:text-48 font-heading font-bold mb-16 text-center">
              <Trans>Join our Community</Trans>
            </h1>
            <p className="text-16 font-base  w-full text-center md:w-[540px]">
              <Trans>
                Paima has a vibrant community of players and developers. Join our Discord server to chat with us, other
                players, and stay up to date on the latest news.
              </Trans>
            </p>
            <div className="w-full flex flex-col justify-between md:flex-row md:flex-wrap md:gap-24 md:justify-center lg:flex-nowrap pb-80 md:pb-0 pt-56 relative z-20">
              <a
                onClick={() => {
                  Analytics.event(AnalyticsEvents.TWITTER, undefined, AnalyticsEventLocations.HOME_JOIN_COMMUNITY);
                }}
                href={socialsConfig.twitterUrl}
                target="_blank"
                className="social-media"
              >
                <div className="p-16 rounded-2xl w-full h-full flex flex-row md:flex-col items-center jusfity-start gap-16 md:justify-center">
                  <img className="md:mb-22" src="/images/icon-twitter.svg" width="48" height="48" alt="Icon Twitter" />
                  <div>
                    <h2 className="text-24 font-bold font-base mb:4 md:mb-14">
                      <Trans>Twitter</Trans>
                    </h2>
                    <p className="text-16 text-engine-primary font-base hover:underline">@PaimaStudios</p>
                  </div>
                </div>
              </a>
              <a
                onClick={() => {
                  Analytics.event(AnalyticsEvents.DISCORD, undefined, AnalyticsEventLocations.HOME_JOIN_COMMUNITY);
                }}
                href={socialsConfig.discordUrl}
                target="_blank"
                className="social-media"
              >
                <div className="p-16 rounded-2xl w-full h-full flex flex-row md:flex-col items-center gap-16 md:justify-center">
                  <img className="md:mb-22" src="/images/icon-discord.svg" width="48" height="48" alt="Icon Discord" />
                  <div>
                    <h2 className="text-24 font-bold font-base mb:4 md:mb-14">
                      <Trans>Discord</Trans>
                    </h2>
                    <p className="text-16 text-engine-primary font-base hover:underline">@PaimaStudios</p>
                  </div>
                </div>
              </a>
              <a
                onClick={() => {
                  Analytics.event(AnalyticsEvents.YOUTUBE, undefined, AnalyticsEventLocations.HOME_JOIN_COMMUNITY);
                }}
                href={socialsConfig.youtubeUrl}
                target="_blank"
                className="social-media"
              >
                <div className="p-16 rounded-2xl w-full h-full flex flex-row md:flex-col items-center gap-16 md:justify-center">
                  <img className="md:mb-22" src="/images/icon-youtube.svg" width="48" height="48" alt="Icon YouTube" />
                  <div>
                    <h2 className="text-24 font-bold font-base mb:4 md:mb-14">
                      <Trans>YouTube</Trans>
                    </h2>
                    <p className="text-16 text-engine-primary font-base hover:underline">@PaimaStudios</p>
                  </div>
                </div>
              </a>
              <a
                onClick={() => {
                  Analytics.event(AnalyticsEvents.REDDIT, undefined, AnalyticsEventLocations.HOME_JOIN_COMMUNITY);
                }}
                href={socialsConfig.redditUrl}
                target="_blank"
                className="social-media"
              >
                <div className="p-16 rounded-2xl w-full h-full flex flex-row md:flex-col items-center gap-16 md:justify-center">
                  <img className="md:mb-22" src="/images/icon-reddit.svg" width="48" height="48" alt="" />
                  <div>
                    <h2 className="text-24 font-bold font-base mb:4 md:mb-14">
                      <Trans>Reddit</Trans>
                    </h2>
                    <p className="text-16 text-engine-primary font-base hover:underline">r/paima</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinCommunitySection;
